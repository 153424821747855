import {Navigate, Outlet} from 'react-router-dom';
import {PRIVATE} from '../../routes/paths';
import { useSelector } from 'react-redux';

const PublicRoute = () => {

    const checkToken = (token) => {
        if ( !token ){
            return false
        }else{
            return true
        }
    }

    const token = useSelector((state) => state.auth.token);

    return ( checkToken(token) ? <Navigate to={PRIVATE} /> : <Outlet /> );

}

export default PublicRoute;