import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import DropDownList from '../DropDownList/DropDownList';
import MessageError from '../MessageError/MessageError';

const DropDownListConceptos = (props) => {

    const [idChild, setIdChild] = useState(props.valuechild);

    const [idParent, setIdParent] = useState(props.valueparent);

    const [itemschildren, setItemsChildren] = useState([]);

    const [childDisabled, setChildDisabled] = useState(true);
  
    const handleParentChange = (e) => {
      setIdParent(e.target.value);
      const selecteditem = props.items.find(element => element.id === e.target.value);
      setItemsChildren(selecteditem.children);
      setChildDisabled(false);
      setIdChild("");
      if(props.onChangeParent){
        props.onChangeParent(e);
      };
    } 

    const handleChildChange = (e) => {
      setIdChild(e.target.value);
      props.onChange(e);
    } 

    return (
      <>
        <FormControl fullWidth sx={{ mb:1 }} >
          <DropDownList 
              id={'id-'+props.id} 
              label={props.labelparent} 
              value={idParent} 
              items={props.items} 
              onChange={handleParentChange} 
          />
        </FormControl>
        <FormControl fullWidth sx={{ mb:1 }} >
          <DropDownList 
              id={'id-sub-'+props.id} 
              label={props.labelchild} 
              value={idChild} 
              items={itemschildren} 
              onChange={handleChildChange} 
              disabled={childDisabled} 
          />
          <MessageError errors={props.error} />
        </FormControl> 
      </>
    )
  }
 
  export default DropDownListConceptos;