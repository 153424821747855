import React from 'react'
import "./Currency.css";


function currencyFormat(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const Currency = (props) => {
  return (
    <span className={`${props.value < 0 ? "negative" : "positive"}`}>{currencyFormat(Math.abs(props.value))}</span>
  )
}

export default Currency;