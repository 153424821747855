import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CollapsibleTableConcepts from '../../components/CollapsibleTableConcepts/CollapsibleTableConcepts';

const Concepts = () => {

    let navigate = useNavigate();

    const buttonExitHandler = (e) => {
        if(e) e.preventDefault();
        navigate("/private");
    } 

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Navbar brand="Mis Gastos" />
            <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                        <AccountTreeIcon sx={{bgcolor: 'success.main' }}/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Conceptos
            </Typography>
            <CollapsibleTableConcepts />
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
                onClick={ buttonExitHandler }
            >
                Volver
            </Button>  
            </LocalizationProvider> 
        </>
    )
}

export default Concepts;