import React from 'react';
import "./DropDownList.css";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';


const DropDownList = (props) => {
  return (
    <>
      <InputLabel id={'label-'+props.id}>{props.label}</InputLabel>
      <Select
          labelId = {'label-'+props.id}
          id = {props.id}
          value = {props.value}
          label = {props.label}
          onChange = {props.onChange}
          disabled = {props.disabled}
          required={props.required}
          multiple={props.multiple}
      >
        {props.items.map((item) => (            
            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
          ))} 
      </Select>
    </>
  )
}

export default DropDownList;