import React from "react";
import FormControl from '@mui/material/FormControl';
import MessageError from '../MessageError/MessageError';
import DropDownList from '../DropDownList/DropDownList';

const DropDownListCantidadCuotas = (props) => {

  const items = [
                    {id:1, label:1},
                    {id:2, label:2},
                    {id:3, label:3},
                    {id:6, label:6},
                    {id:12, label:12},
                    {id:18, label:18}
                ];

  return (
    <FormControl fullWidth sx={{ mb:1 }} >
        <DropDownList
            id='cantidadcuotas'
            label='Cantidad de cuotas'
            value = {props.value}
            onChange = {props.onChange}
            disabled = {props.disabled}
            items={items}
        >
        </DropDownList>
        <MessageError errors={props.error} />
    </FormControl>  
  );
};

export default DropDownListCantidadCuotas;