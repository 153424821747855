import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";

const Copyright = (props) => {
    return (
      <Typography variant="body2" color="textSecondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit"  to="/">
          Mis gastos
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
}

export default Copyright