import React from "react";
import "./MessageError.css";

const MessageError = (props) => {

  return (
    <>
      {props.errors && <p className="message-error">{props.errors}</p>}
    </>
  );
};

export default MessageError;