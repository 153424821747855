import { Button, Grid } from '@mui/material';
import { Container } from '@mui/system';
import React from 'react';
import "./Home.css";
import { useNavigate } from "react-router-dom";


const Home = () => {

  let navigate = useNavigate(); 

  const buttonHandler = (path) => {
    navigate(path)
  }

  return (
  <Container maxWidth="sm" p={2}>
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Button 
              variant="contained" 
              size="large" 
              m="auto"
              // startIcon={<AddShoppingCartOutlined />} 
              sx={{
                height: 100,
                width: 250
              }}
              onClick={() => { buttonHandler("/login") }}
          >
            Login
          </Button>              
      </Grid>
    </Grid>
    </Container>
  )
}

export default Home;