export const LOGIN = '/login';
export const FORGOTPASSWORD = '/forgot-password';
export const CHANGEPASSWORD = '/change-password';
export const HOME = '/';
export const LOGOUT = '/private/logout';
export const PAY = '/private/pay';
export const TRANSFER = '/private/transfer';
export const INCOME = '/private/income';
export const ACCOUNTS = '/private/accounts';
export const CONCEPTS = '/private/concepts';
export const ACCOUNTSDUES = '/private/accountsdues';
export const NEXTDEBS = '/private/nextdebs';
export const PRIVATE = '/private';