import React from 'react';
import {HashRouter, Routes, Route} from 'react-router-dom';
import Login from '../views/Login/Login';
import ForgotPassword from '../views/ForgotPassword/ForgotPassword';
import ChangePassword from '../views/ChangePassword/ChangePassword';
import Home from '../views/Home/Home';
import Logout from '../views/Logout/Logout';
import Main from '../views/Main/Main';
import Pay from '../views/Pay/Pay';
import Income from '../views/Income/Income';
import Transfer from '../views/Transfer/Transfer';
import Accounts from '../views/Accounts/Accounts';
import Concepts from '../views/Concepts/Concepts';
import NextDebs from '../views/NextDebs/NextDebs';
import AccountsDues from '../views/AccountsDues/AccountsDues';
import {LOGIN, FORGOTPASSWORD, CHANGEPASSWORD, HOME, LOGOUT, PRIVATE, PAY, TRANSFER, INCOME, ACCOUNTS, CONCEPTS, ACCOUNTSDUES, NEXTDEBS} from './paths';
import PublicRoute from '../views/router/PublicRoute';
import PrivateRoute from '../views/router/PrivateRoute';

const MyRoutes = () => {

    return(
            <HashRouter>
                <Routes>
                    <Route path="/" element={<PublicRoute />}>
                        <Route path={HOME} element={<Home />}/>
                        <Route path={LOGIN} element={<Login />}/>
                        <Route path={FORGOTPASSWORD} element={<ForgotPassword />}/>
                        <Route path={CHANGEPASSWORD} element={<ChangePassword />}/>
                    </Route>
                    <Route path={PRIVATE} element={<PrivateRoute />}>
                        <Route index element={<Main />}/>
                        <Route path={LOGOUT} element={<Logout />}/>
                        <Route path={PAY} element={<Pay />}/>
                        <Route path={TRANSFER} element={<Transfer />}/>
                        <Route path={INCOME} element={<Income />}/>
                        <Route path={ACCOUNTS} element={<Accounts />}/>
                        <Route path={CONCEPTS} element={<Concepts />}/>
                        <Route path={ACCOUNTSDUES} element={<AccountsDues />}/>
                        <Route path={NEXTDEBS} element={<NextDebs />}/>
                       </Route>
                </Routes>                
            </HashRouter>
    );
}

export default MyRoutes;