import MyRoutes from './routes/Routes';
import { Provider } from 'react-redux';
import store from './store/store';
import { AuthControl } from './components/AuthControl/AuthControl';

const App = (props) => {

    return (
        <Provider store={store}>
            <AuthControl />
            <MyRoutes />
        </Provider>
    );
};

export default App;