import {Navigate, Outlet} from 'react-router-dom';
import {LOGIN} from '../../routes/paths';
import { useSelector } from 'react-redux';


const PrivateRoute = () => {

    const checkToken = (token) => {
        if ( !token ){
            return false
        }else{
            return true
        }
    }

    const token = useSelector((state) => state.auth.token);
   
    return ( checkToken(token) ? <Outlet /> : <Navigate to={LOGIN} /> );

}

export default PrivateRoute;