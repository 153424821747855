import React from 'react';
import './RowConcept.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, TableRow, TableCell, TableBody, TableHead, Box, Table } from '@material-ui/core';
import { Fragment } from 'react';
import Currency from '../../components/Currency/Currency';
import Loader from '../../components/Loader/Loader';
import CommonDataService from  '../../services/commonService';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';

const RowConcept = (props) => {

    const { row, idperiodo, idpagadores} = props;
    const [isDataFetch, setIsDataFetch] = useState(false);
    const [movimientos, setMovimientos] = useState([]);
    const [open, setOpen] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);

    const token = useSelector((state) => state.auth.token);
    const idgroup = useSelector((state) => state.common.idGroup);

    const expandIconClickHandler = (idConcepto) => {
        setOpen(!open);
        if(!isDataFetch){
            setIsDetailLoading(true);
            CommonDataService.getAllConceptoMovimientos(token, idgroup, idConcepto, idperiodo, idpagadores.join())
                .then(res =>{
                    setIsDataFetch(true);
                    setMovimientos(res.data);
                    setIsDetailLoading(false);
                });           
        }
    };

    return (   
        <Fragment key={row.idConcepto}>
        <TableRow key={row.idConcepto+'_row'} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
            {/* <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => expandIconClickHandler(row.idConcepto)} 
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> */}
                <Button onClick={() => expandIconClickHandler(row.idConcepto)}>
                    {open ? "-" : "+"}
                </Button>
            </TableCell>
            <TableCell component="th" scope="row">{row.conceptoPadreDescripcion}</TableCell>
            <TableCell>{row.conceptoDescripcion}</TableCell>
            <TableCell align="right"><Currency value={row.totalPresupuesto}/></TableCell>
            <TableCell align="right"><Currency value={row.totalGasto}/></TableCell>
            <TableCell align="right"><Currency value={row.totalPresupuesto - row.totalGasto}/></TableCell>
        </TableRow>
        <TableRow key={row.idConcepto+'_rowdetail'}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                <h2>
                    Detalle de movimientos
                </h2>
                {isDetailLoading && <Loader />}
                <Table sx={{ minWidth: 120 }} size="small" aria-label="purchases">
                    <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Descripcion</TableCell>
                        <TableCell align="right">Importe</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {movimientos.map((movimiento) => (
                        <TableRow key={movimiento.IdMovimiento}>
                            <TableCell component="th" scope="row">{movimiento.FechaMovimiento}</TableCell>
                            <TableCell>{movimiento.Descripcion}</TableCell>
                            <TableCell align="right"><Currency value={movimiento.Importe}/></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        </Fragment>
    )
}

export default RowConcept;
