import React, { useEffect } from 'react';
import './Logout.css';

import { useDispatch } from 'react-redux';
import { logoutAction } from '../../store/slices/authSlice';
import { resetData } from '../../store/slices/commonSlice';

const Logout = () => {

  const dispatch = useDispatch();

  useEffect(()=>{    
    dispatch( logoutAction() );
    dispatch( resetData() );
  });

  return (
    <div></div>
  )
};

export default Logout;