import React from 'react';
import './RowAccount.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, TableRow, TableCell, TableBody, TableHead, Box, Table } from '@material-ui/core';
import { Fragment } from 'react';
import Currency from '../../components/Currency/Currency';
import Loader from '../../components/Loader/Loader';
import CommonDataService from  '../../services/commonService';
//import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';

const RowAccount = (props) => {

    const { row } = props;
    const [isDataFetch, setIsDataFetch] = useState(false);
    const [movimientos, setMovimientos] = useState([]);
    const [open, setOpen] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);

    const token = useSelector((state) => state.auth.token);
    const idgroup = useSelector((state) => state.common.idGroup);

    const expandIconClickHandler = (idCuenta) => {
        setOpen(!open);
         if(!isDataFetch){
             setIsDetailLoading(true);
             CommonDataService.getAllCuentaMovimientos(token, idgroup, idCuenta)
                 .then(res =>{
                     setIsDataFetch(true);
                     setMovimientos(res.data);
                     setIsDetailLoading(false);
                 });           
         }
    };

    return (   
        <Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <Button onClick={() => expandIconClickHandler(row.IdCuenta)}>
                        {open ? "-" : "+"}
                    </Button>
                    {/* <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => expandIconClickHandler(row.IdCuenta)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton> */}
                </TableCell>
                <TableCell component="th" scope="row">{row.Descripcion}</TableCell>
                <TableCell align="right"><Currency value={row.Saldo}/></TableCell>
            </TableRow>
             <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <h2>
                        Detalle de movimientos
                    </h2>
                    {isDetailLoading && <Loader />}
                    <Table sx={{ minWidth: 120 }} size="small" aria-label="purchases">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Descripcion</TableCell>
                                <TableCell align="right">Importe</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {movimientos.map((movimiento) => (
                                <TableRow key={movimiento.IdMovimiento}>
                                    <TableCell component="th" scope="row">{movimiento.FechaMovimiento}</TableCell>
                                    <TableCell>{movimiento.Descripcion}</TableCell>
                                    <TableCell align="right"><Currency value={movimiento.Importe}/></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>  
                    </Box>
                </Collapse>
                </TableCell> 
            </TableRow> 
        </Fragment>
    )
}

export default RowAccount;
