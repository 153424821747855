import React from "react";
import FormControl from '@mui/material/FormControl';
import MessageError from '../MessageError/MessageError';
import NumericInput from 'material-ui-numeric-input';

const NumberInput = (props) => {

  return (
    <FormControl fullWidth sx={{ mb:1 }} >
        <NumericInput
            name={props.name}
            precision={props.precision ? props.precision : 2}
            decimalChar={props.decimalChar ? props.decimalChar : ',' } 
            thousandChar={props.thousandChar ? props.thousandChar : '.' }
            label={props.label}
            onChange={props.onChange}
            variant={props.variant ? props.variant : 'outlined' }
            value={props.value}
            required={props.required}
            fullWidth
        />
        <MessageError errors={props.error} />
    </FormControl>  
  );
};

export default NumberInput;