import http from "./http-common";

class authService {
    setAutorization(token){
        return {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    }
    login(email, password){
        let datosLogin = {
            email,
            password
        };
        return http.post('/login', datosLogin);
    };
    sendResetPassword(email){
        let datosLogin = {
            email
        };
        return http.post('/resetpassword', datosLogin);
    }
    sendChangePassword(email, password, token){
        let datosLogin = {
            email,
            password
        };
        return http.post('/changepassword', datosLogin, this.setAutorization(token));
    }
    refreshToken(){
        return http.post('/refreshtoken');
    }
};

export default new authService();