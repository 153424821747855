import React from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import "./Login.css";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextInput from '../../components/TextInput/TextInput';
import Loader from '../../components/Loader/Loader';
import { useDispatch } from 'react-redux';
import { loginThunk, commondataThunk, setAuthenticatedThunk } from '../../store/slices';
import Copyright from '../../components/Copyright/Copyright';
import MessageError from '../../components/MessageError/MessageError';

const Login = () => {

    const [email, setEMail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailError, setEMailError] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const buttonHandler = (e) => {
        e.preventDefault();
        if(handleValidation()){
            setIsLoading(true);
            dispatch( loginThunk(email, password) )
                .then((res) => {                    
                    if (!res){
                        setIsLoading(false);
                        setLoginError("Ha ocurrido un error. Por favor reintente");
                    }else{
                      const token = res;
                      dispatch(commondataThunk(token))
                        .then(()=> {
                            setIsLoading(false);
                            dispatch(setAuthenticatedThunk());
                        });
                    }                  
                });              
        }
    } 

    const handleValidation = () => {
        if (!email.match(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/)){
            setEMailError("Debe ingresar una cuenta de correo electrónico válida");
            return false;
        } else {
            setEMailError("");
        }
        if (!password.match(/^[a-zA-Z0-9\u00F1\u00D1\u002D@.&!$()?<>=_/:°%]{8,22}$/)) {
            setPasswordError("Sólo letras, números y caracteres especiales y el largo debe estar entre 8 y 22 caracteres");
            return false;
        } else {
            setPasswordError("");
        }
        return true;
    }

    return (
        <Container component="main" maxWidth="xs" >
        <Box
          sx={{
            marginTop: 8,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: "auto", bgcolor:'secondary.main' }}>
            <LockOutlinedIcon sx={{ bgcolor:'secondary.main'  }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Mis Gastos - Ingreso
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextInput
              id="email"
              label="Correo electrónico"
              name="email"
              value={email}
              onChange={e => setEMail(e.target.value)}
              error={emailError}
              autoComplete="email"
              required
              autoFocus
            />
            <TextInput
              id="password"
              label="Contraseña"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              autoFocus
              value={password}
              onChange={e => setPassword(e.target.value)}
              error={passwordError}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={buttonHandler}
            >
              Ingresar
            </Button>
            <Box         
              display="flex" 
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <Loader/>}
            </Box> 
            <MessageError errors={loginError} />           
            <Box         
              display="flex" 
              alignItems="center"
              justifyContent="center"
            >
                <Link to="/forgot-password" variant="body2">
                    Olvidó la contraseña?
                </Link>
            </Box>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    );
};

export default Login;