import React from 'react';
import "./ChangePassword.css";
import {useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextInput from '../../components/TextInput/TextInput';
import Loader from '../../components/Loader/Loader';
import Copyright from '../../components/Copyright/Copyright';
import AuthDataService from '../../services/authService';
import MessageError from '../../components/MessageError/MessageError';
import { useSearchParams } from "react-router-dom";
import jwt from 'jwt-decode';

const ChangePassword = () => {

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [password1, setPassword1] = useState('');
    const [password1Error, setPassword1Error] = useState('');

    const [resetPasswordError, setResetPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [searchParams] = useSearchParams();

    const token = searchParams.get('tkn');

    let email = "";

    if (token){
      const data = JSON.parse(jwt(token).data);
      email = data.email;
    }else{
      setResetPasswordError("La operación que intenta ejecutar no está permitida");
    }

    const buttonHandler = (e) => {
        e.preventDefault();

        if(handleValidation()){
            setIsLoading(true);

            AuthDataService.sendChangePassword(email, password, token)
                .then((res) => {
                    setIsLoading(false);
                    if (res){
                        setResetPasswordError("Se ha cambiado la contraseña.");
                    }else{
                        setResetPasswordError("Ha ocurrido un error al intentar cambiar la contraseña. Por favor intente nuevamente mas tarde.");
                    }                  
                })
                .catch((error) => {
                    if(error.response.status === 401){
                      setResetPasswordError("No está autorizado para realizar el cambio de contraseña. Por favor intente nuevamente generando la solicitud de cambio.");
                    }else{
                      setResetPasswordError("Ha ocurrido un error al intentar cambiar la contraseña. Por favor intente nuevamente mas tarde.");
                    };
                    setIsLoading(false);
                  });              
        }
    } 

    const handleValidation = () => {
        if (!password.match(/^[a-zA-Z0-9\u00F1\u00D1\u002D@.&!$()?<>=_/:°%]{8,22}$/)) {
            setPasswordError("Sólo letras, números y caracteres especiales y el largo debe estar entre 8 y 22 caracteres");
            return false;
        } else {
            setPasswordError("");
        }
        if (!password1.match(/^[a-zA-Z0-9\u00F1\u00D1\u002D@.&!$()?<>=_/:°%]{8,22}$/)) {
            setPassword1Error("Sólo letras, números y caracteres especiales y el largo debe estar entre 8 y 22 caracteres");
            return false;
        } else {
            setPassword1Error("");
        }
        if (password !== password1) {
            setPassword1Error("Las contraseñas ingresadas no coinciden");
            return false;
        } else {
            setPassword1Error("");
        }
        return true;
    }

    return (
        <Container component="main" maxWidth="xs" >
        <Box
          sx={{
            marginTop: 8,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: "auto", bgcolor:'secondary.main' }}>
            <LockOutlinedIcon sx={{ bgcolor:'secondary.main'  }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Mis Gastos - Cambio de contraseña
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextInput
                id="password"
                label="Contraseña"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                autoFocus
                value={password}
                onChange={e => setPassword(e.target.value)}
                error={passwordError}
            />
            <TextInput
                id="password1"
                label="Repita Contraseña"
                name="password1"
                type="password"
                autoComplete="current-password1"
                required
                autoFocus
                value={password1}
                onChange={e => setPassword1(e.target.value)}
                error={password1Error}
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={buttonHandler}
            >
              Enviar
            </Button>
            <Box         
              display="flex" 
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <Loader/>}
            </Box>
            <MessageError errors={resetPasswordError} />        
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    );
};

export default ChangePassword;