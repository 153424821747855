import { useState } from "react";
import { useSelector } from 'react-redux';


export const useForm = (initialForm, validateForm, submitForm) => {

    const [form, setForm] = useState(initialForm);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [responseError, setResponseError] = useState(null);

    const token = useSelector((state) => state.auth.token);

    const idgroup = useSelector((state) => state.common.idGroup);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setForm({
        ...form,
        [name]: value,
      });
    };

    const handleBlur = (e) => {
      handleChange(e);
      setErrors(validateForm(form));
    };

    const handleSubmit = async (e) => {
  
        if(e) e.preventDefault();

        let errors = validateForm(form);

        setErrors(errors);

        if (Object.keys(errors).length === 0) {

          setLoading(true);

          try{
              await submitForm(token, idgroup, form);
              setLoading(false);
              setResponse(true);
              return true;
          }catch(error){
              setLoading(false);
              setResponseError(true);
              return false;
          }

        } else {
            return false;
        }
  };

  return {
    form,
    errors,
    loading,
    response,
    responseError,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};