import React from 'react';
import './SwitchIncluyeTarjetas.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const SwitchIncluyeTarjetas = (props) => {

  return (
    <FormGroup>
        <FormControlLabel control={<Switch checked={props.value} onChange={props.onChange} />} label={props.value ? "Incluye tarjetas de crédito" : "No incluye tarjetas de crédito"} />
    </FormGroup>
  )
}

export default SwitchIncluyeTarjetas;
