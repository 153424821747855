import { createSlice } from '@reduxjs/toolkit';

const GROUPS = '_MG_GROUPS';
const MONEDAS = '_MG_MONEDAS';
const PAGADORES = '_MG_PAGADORES';
const CONCEPTOSGASTOS = '_MG_CONCEPTOSGASTOS';
const CONCEPTOSINGRESOS = '_MG_CONCEPTOSINGRESOS';
const TIPOCUENTASPAGO = '_MG_TIPOCUENTASPAGO';
const CUENTASPAGO = '_MG_CUENTASPAGO';
const CUENTASMOVIMIENTO = '_MG_CUENTASMOVIMIENTO';
const IDMONEDA = '_MG_IDMONEDA';
const IDGROUP = '_MG_IDGROUP';

export const commonSlice = createSlice({
    name: 'common',
    initialState:{
        isLoading:false,
        isError:false,
        groups: (window.localStorage.getItem(GROUPS) === undefined || window.localStorage.getItem(GROUPS) === null ) ? [] : JSON.parse(window.localStorage.getItem(GROUPS)),
        monedas: (window.localStorage.getItem(MONEDAS) === undefined || window.localStorage.getItem(MONEDAS) === null ) ? [] : JSON.parse(window.localStorage.getItem(MONEDAS)),
        pagadores: (window.localStorage.getItem(PAGADORES) === undefined || window.localStorage.getItem(PAGADORES) === null ) ? [] : JSON.parse(window.localStorage.getItem(PAGADORES)),
        idMoneda: window.localStorage.getItem(IDMONEDA) ?? null,
        idGroup: window.localStorage.getItem(IDGROUP) ?? null,
        conceptosGastos: (window.localStorage.getItem(CONCEPTOSGASTOS) === undefined || window.localStorage.getItem(CONCEPTOSGASTOS) === null ) ? [] : JSON.parse(window.localStorage.getItem(CONCEPTOSGASTOS)),
        conceptosIngresos: (window.localStorage.getItem(CONCEPTOSINGRESOS) === undefined || window.localStorage.getItem(CONCEPTOSINGRESOS) === null ) ? [] : JSON.parse(window.localStorage.getItem(CONCEPTOSINGRESOS)),
        tipoCuentasPago: (window.localStorage.getItem(TIPOCUENTASPAGO) === undefined || window.localStorage.getItem(TIPOCUENTASPAGO) === null ) ? [] : JSON.parse(window.localStorage.getItem(TIPOCUENTASPAGO)),
        cuentasPago: (window.localStorage.getItem(CUENTASPAGO) === undefined || window.localStorage.getItem(CUENTASPAGO) === null ) ? [] : JSON.parse(window.localStorage.getItem(CUENTASPAGO)),
        cuentasMovimiento: (window.localStorage.getItem(CUENTASMOVIMIENTO) === undefined || window.localStorage.getItem(CUENTASMOVIMIENTO) === null ) ? [] : JSON.parse(window.localStorage.getItem(CUENTASMOVIMIENTO))
    },
    reducers:{
      setGroups: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.groups = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(GROUPS, data_str);
      },
      setGroup: (state, action) => {
        state.idGroup = action.payload;
        window.localStorage.setItem(IDGROUP, action.payload);
      },
      setMonedas: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.monedas = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(MONEDAS, data_str);
      },
      setMoneda: (state, action) => {
        state.idMoneda = action.payload;
        window.localStorage.setItem(IDMONEDA, action.payload);
      },
      setPagadores: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.pagadores = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(PAGADORES, data_str);
      },
      setConceptosGastos: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.conceptosGastos = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(CONCEPTOSGASTOS, data_str);
      },
      setConceptosIngresos: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.conceptosIngresos = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(CONCEPTOSINGRESOS, data_str);
      },
      setTipoCuentasPago: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.tipoCuentasPago = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(TIPOCUENTASPAGO, data_str);
      },
      setCuentasPago: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.cuentasPago = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(CUENTASPAGO, data_str);
      },
      setCuentasMovimiento: (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.cuentasMovimiento = action.payload;
        const data_str = JSON.stringify(action.payload);
        window.localStorage.setItem(CUENTASMOVIMIENTO, data_str);
      },
      startGetData: (state) => {
        state.isLoading = true;
        state.isError = false;
      },
      startError: (state) => {
        state.isError = true;
        state.isLoading = false;
      },
      resetData: (state) => {
        state.groups = [];
        state.monedas= [];
        state.pagadores = [];
        state.idMoneda = null;
        state.idGroup = null;
        state.conceptosGastos = [];
        state.conceptosIngresos = [];
        state.tipoCuentasPago = [];
        state.cuentasPago = [];
        state.cuentasMovimiento = [];
        window.localStorage.removeItem(GROUPS);
        window.localStorage.removeItem(CONCEPTOSGASTOS);
        window.localStorage.removeItem(CONCEPTOSINGRESOS);
        window.localStorage.removeItem(TIPOCUENTASPAGO);
        window.localStorage.removeItem(CUENTASPAGO);
        window.localStorage.removeItem(CUENTASMOVIMIENTO);
        window.localStorage.removeItem(PAGADORES);
        window.localStorage.removeItem(MONEDAS);
        window.localStorage.removeItem(IDMONEDA);
        window.localStorage.removeItem(IDGROUP);
      },
    },
});

export default commonSlice.reducer;
export const { setGroups, setGroup, setMonedas, setMoneda, setPagadores, setConceptosGastos, setConceptosIngresos, setTipoCuentasPago, setCuentasPago, setCuentasMovimiento, resetData, startGetData, startError } = commonSlice.actions;

