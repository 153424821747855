import React from 'react';
import "./Main.css";
import Navbar from '../../components/Navbar/Navbar';
import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import AddShoppingCartOutlined from '@mui/icons-material/AddShoppingCartOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import PriceCheckOutlinedIcon  from '@mui/icons-material/PriceCheckOutlined';
import AccountBalanceWalletIcon  from '@mui/icons-material/AccountBalanceWallet';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EventIcon from '@mui/icons-material/Event';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

const Main = () => {
 
  let navigate = useNavigate(); 

  const buttonHandler = (path) => {
    navigate(path)
  }

  return (
    <>
        <Navbar brand="Mis Gastos" />
        <Container maxWidth="sm" p={2}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  m="auto"
                  startIcon={<AddShoppingCartOutlined />} 
                  sx={{
                    height: 100,
                    width: 250
                  }}
                  onClick={() => { buttonHandler("/private/pay") }}
              >
                Nuevo egreso
              </Button>              
          </Grid>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  startIcon={<CurrencyExchangeOutlinedIcon />} 
                  sx={{ height: 100, width: 250 }}
                  onClick={() => { buttonHandler("/private/transfer") }}
              >
                Transferencia
              </Button>            
          </Grid>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  startIcon={<PriceCheckOutlinedIcon />} 
                  sx={{ height: 100, width: 250 }}
                  onClick={() => { buttonHandler("/private/income") }}
                >
                Nuevo ingreso
              </Button>             
          </Grid>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  startIcon={<AccountBalanceWalletIcon />} 
                  sx={{ height: 100, width: 250 }}
                  onClick={() => { buttonHandler("/private/accounts") }}
                >
                Cuentas
              </Button>             
          </Grid>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  startIcon={<AccountTreeIcon />} 
                  sx={{ height: 100, width: 250 }}
                  onClick={() => { buttonHandler("/private/concepts") }}
                >
                Conceptos
              </Button>             
          </Grid>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  startIcon={<CreditCardIcon />} 
                  sx={{ height: 100, width: 250 }}
                  onClick={() => { buttonHandler("/private/accountsdues") }}
                >
                Próximas cuotas
              </Button>             
          </Grid>
          <Grid item xs={12}>
            <Button 
                  variant="contained" 
                  size="large" 
                  startIcon={<EventIcon />} 
                  sx={{ height: 100, width: 250 }}
                  onClick={() => { buttonHandler("/private/nextdebs") }}
                >
                Próximos vencimientos
              </Button>             
          </Grid>
        </Grid>
        </Container>
      </>
  )
}

export default Main;