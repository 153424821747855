import { createSlice } from '@reduxjs/toolkit';

const ISAUTHENTICATED = '_MG_ISAUTHENTICATED';

export const authSlice = createSlice({
    name: 'users',
    initialState:{
        isAuthenticated: window.localStorage.getItem(ISAUTHENTICATED) === 'true' ?? null,
        isLoginLoading:false,
        isLoginError:false,
        iduser: null,
        token: null,
        idpagador: null,
        username: null,
        email: null,
    },
    reducers:{
      login: (state, action) => {
        state.isLoginLoading = false;
        state.isLoginError = false;
        state.token = action.payload.token;
        state.iduser = action.payload.iduser * 1;
        state.idpagador = action.payload.idpagador * 1;
        state.username = action.payload.username;
        state.email = action.payload.email;
      },
      setAuthenticated: (state) => {
        state.isAuthenticated = true;
        window.localStorage.setItem(ISAUTHENTICATED, true);
      },
      logout: (state) => {
        state.isAuthenticated = false;
        state.isLoginError = false;
        state.token = null;
        state.iduser = null;
        state.idpagador = null;
        state.username = null;
        state.email = null;
        window.localStorage.removeItem(ISAUTHENTICATED);
      },
      startLogin: (state) => {
        state.isLoginLoading = true;
        state.isLoginError = false;
      },
      startLoginError: (state) => {
        state.isLoginError = true;
        state.isLoginLoading = false;
      },
      refreshtoken: (state, action) => {
        state.token = action.payload.token;
        state.iduser = action.payload.iduser * 1;
        state.idpagador = action.payload.idpagador * 1;
        state.username = action.payload.username;
        state.email = action.payload.email;
      },
    },
});

export default authSlice.reducer;
export const { login, setAuthenticated, logout:logoutAction, startLogin, startLoginError, refreshtoken } = authSlice.actions;

