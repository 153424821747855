import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import DropDownListParentChild from '../DropDownListParentChild/DropDownListParentChild';

const DropDownListConceptos = (props) => {

  const conceptos = useSelector((state) => { if (props.tipo ==='I') { return state.common.conceptosIngresos } else { return state.common.conceptosGastos } });

  const [idconceptopadre, setConceptoPadre] = useState("");
  const [idconceptohijo, setConceptoHijo] = useState("");

  const items = conceptos.map(item => {
    return {
               id: item.IdConcepto,
               label: item.Descripcion,
               children: item.conceptoshijos.map(subitem => {
                    return {
                      id: subitem.IdConcepto,
                      label: subitem.Descripcion
                    };  
               })
             };
  });

  return (
    <>
        <DropDownListParentChild 
          id="Concepto"
          labelparent="Categoria Concepto"
          valueparent={idconceptopadre}
          labelchild="Sub-Categoria Concepto"
          items={items}
          valuechild={idconceptohijo}
          onChange={props.onChange}
          error={props.error}
        >
        </DropDownListParentChild>
    </>
  )
}

export default DropDownListConceptos;