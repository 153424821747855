import React from 'react';
import './CollapsibleTableAccountsDues.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Row from '../RowAccountDue/RowAccountDue';
import Loader from '../../components/Loader/Loader';
import CommonDataService from  '../../services/commonService';

const CollapsibleTableAccountsDues = () => {

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const token = useSelector((state) => state.auth.token);
    const idgroup = useSelector((state) => state.common.idGroup);
    const idmoneda = useSelector((state) => state.common.idMoneda);

    const getDate = ( dateStr ) => {
        const [year, month, day] = [...dateStr.split("-")];
        const monthIndex = month - 1; 
        return new Date(year, monthIndex, day);
    }

    const addMonth = (adate, months ) => {
        let day=adate.getDate();
        let month=adate.getMonth() + months;
        let year=adate.getFullYear();
        let addyears=Math.trunc(month/12);
        return new Date(year+addyears,month % 12,day)
    }

    const getMonth = (adate) => {
        const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'];
        console.log(adate.getMonth(), adate.getFullYear());
        return months[adate.getMonth()] + "/" + adate.getFullYear();
    }

    const getMonths = ( beginDate, endDate) => {
        if (beginDate && endDate){
            let aMonths = [];
            let date = new Date(beginDate.getTime());
            while (date.getTime() <= endDate.getTime() ) {
                aMonths.push(date);
                date = addMonth(date, 1);
            }
            return aMonths;
        }else{
            return [];
        }
    }

    useEffect(() => {
        setIsLoading(true);
        CommonDataService.getAllProximasCuotasTarjeta(token, idgroup, idmoneda)
            .then(res =>{
                let months=[];
                let rows=[];
                let minFechaPeriodo=null;
                let maxFechaPeriodo=null;
                res.data.forEach(item =>{
                    let fechaPeriodo=getDate(item.FechaPeriodo);
                    minFechaPeriodo = (minFechaPeriodo ? ( fechaPeriodo < minFechaPeriodo ? fechaPeriodo: minFechaPeriodo) : fechaPeriodo);
                    maxFechaPeriodo = (maxFechaPeriodo ? ( fechaPeriodo > maxFechaPeriodo ? fechaPeriodo: maxFechaPeriodo) : fechaPeriodo);
                });
                months = getMonths(minFechaPeriodo, maxFechaPeriodo);
                let idcuentaant=null;
                let i=-1;
                res.data.forEach(item =>{
                    let fechaPeriodo=getDate(item.FechaPeriodo);
                    if (idcuentaant !== item.IdCuenta){
                        rows.push({idcuenta: item.IdCuenta, cuenta:item.Cuenta, cols: Array(months.length).fill(0.0)});
                        i++;
                    };
                    let index = months.findIndex( (month) => ( fechaPeriodo.getTime() === month.getTime() ) )
                    rows[i].cols[index]=item.ImporteCuota;
                    idcuentaant=item.IdCuenta; 
                });
                setRows(rows);
                setColumns(months);
                setIsLoading(false);
            });           
    }, [token, idgroup, idmoneda]);
    

    return (
        <>
            {isLoading && <Loader />}
            <TableContainer>        
                <Table sx={{ minWidth: 90 }} size="small" aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>Cuenta</TableCell>
                            {columns.map((col, index) => (
                            <TableCell key={index} align="right">{getMonth(col)}</TableCell>
                            ))}  
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <Row key={row.cuenta} row={row} columns={columns}/>
                        ))}  
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CollapsibleTableAccountsDues;