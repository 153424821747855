import React from 'react'
import "./AccountsDues.css";
import Navbar from '../../components/Navbar/Navbar';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CollapsibleTableAccountsDues from '../../components/CollapsibleTableAccountsDues/CollapsibleTableAccountsDues';

const AccountsDues = () => {

  let navigate = useNavigate();

  const buttonExitHandler = (e) => {
    if(e) e.preventDefault();
    navigate("/private");
  } 

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Navbar brand="Mis Gastos" />
      <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                  <CreditCardIcon sx={{bgcolor: 'success.main' }}/>
      </Avatar>
      <Typography component="h1" variant="h5">
          Próximos vencimientos
      </Typography>
      <CollapsibleTableAccountsDues/>
      <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
          onClick={ buttonExitHandler }
      >
          Volver
      </Button>
    </LocalizationProvider>
    </>
  )
}

export default AccountsDues;