import {configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import commonReducer from './slices/commonSlice';

export default configureStore({
    reducer:{
        auth: authReducer,
        common: commonReducer,        
    },
    devTools: process.env.REACT_APP_ENABLE_REDUX_DEV_TOOLS,
});

