import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import "./NextDebs.css";
import Navbar from '../../components/Navbar/Navbar';
import EventIcon from '@mui/icons-material/Event';
import CircleIcon from '@mui/icons-material/Circle';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Loader from '../../components/Loader/Loader';
import Currency from '../../components/Currency/Currency';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CommonDataService from  '../../services/commonService';

const NextDebs = () => {

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const token = useSelector((state) => state.auth.token);
  const idgroup = useSelector((state) => state.common.idGroup);
  const idmoneda = useSelector((state) => state.common.idMoneda);

  let navigate = useNavigate();

  const buttonExitHandler = (e) => {
    if(e) e.preventDefault();
    navigate("/private");
  } 

  useEffect(() => {
    setIsLoading(true);
    CommonDataService.getAllProximosVencimientos(token, idgroup, idmoneda)
        .then(res =>{
            setRows(res.data);
            setIsLoading(false);
        });           
  }, [token, idgroup, idmoneda]);

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Navbar brand="Mis Gastos" />
      <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                  <EventIcon sx={{bgcolor: 'success.main' }}/>
      </Avatar>
      <Typography component="h1" variant="h5">
          Próximos vencimientos
      </Typography>
      {isLoading && <Loader />}

      <TableContainer>        
        <Table sx={{ minWidth: 90 }} size="small" aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Fecha Vto.</TableCell>
                    <TableCell>Descripción</TableCell>
                    <TableCell align="right">Importe</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell><CircleIcon sx={{color:  (row.Vencido ? 'error.main' : 'success.main')  }}/></TableCell>
                    <TableCell>{row.FechaVencimiento}</TableCell>
                    <TableCell>{row.Descripcion}</TableCell>
                    <TableCell align="right"><Currency value={row.ImportePresupuesto}/></TableCell>
                  </TableRow>
                ))}  
            </TableBody>
        </Table>
      </TableContainer>
      <Button
          type="submit"
          variant="contained"
          sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
          onClick={ buttonExitHandler }
      >
          Volver
      </Button>
    </LocalizationProvider>
    </>
  )
}

export default NextDebs;