import React from "react";
import FormControl from '@mui/material/FormControl';
import MessageError from '../MessageError/MessageError';
import DropDownList from '../DropDownList/DropDownList';
import { useSelector } from 'react-redux';

const DropDownListCuentas = (props) => {

  const tipomovimiento = props.tipomovimiento;

  const origendestino = props.origendestino;

  const grupo = props.grupo;

  const cuentasMovimientos = useSelector((state) => state.common.cuentasMovimiento);

  const cuentasitems = cuentasMovimientos.filter(element => {
      return ( 
        (tipomovimiento === 'T' && origendestino === 'O' && element.TransferenciaOrigen==='S' && element.CuentaPropia === 'S') ||
        (tipomovimiento === 'T' && origendestino === 'D' && element.TransferenciaDestino==='S' && (grupo === 'S' || element.CuentaPropia === 'S')) ||
        (tipomovimiento === 'M' && origendestino === 'O' && element.MovimientoOrigen==='S' && element.CuentaPropia === 'S') ||
        (tipomovimiento === 'M' && origendestino === 'D' && element.MovimientoDestino==='S' && (grupo === 'S' || element.CuentaPropia === 'S')) ||
        (tipomovimiento === 'E' && origendestino === 'O' && element.ExtraccionOrigen==='S' && element.CuentaPropia === 'S') ||
        (tipomovimiento === 'E' && origendestino === 'D' && element.ExtraccionDestino==='S' && element.CuentaPropia === 'S') ||
        (tipomovimiento === 'D' && origendestino === 'O' && element.DepositoOrigen==='S' && element.CuentaPropia === 'S') ||
        (tipomovimiento === 'D' && origendestino === 'D' && element.DepositoDestino==='S' && element.CuentaPropia === 'S'))
  });
  
  const items = cuentasitems.map(item => {
    return {
               id: item.IdCuenta,
               label: item.CuentaDescripcion,
             };
  });

  return (
    <FormControl fullWidth sx={{ mb:1 }} >
        <DropDownList
            id={props.id}
            label={props.label}
            value = {props.value}
            onChange = {props.onChange}
            disabled = {props.disabled}
            items={items}
        >
        </DropDownList>
        <MessageError errors={props.error} />
    </FormControl>  
  );
};

export default DropDownListCuentas;