import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import DropDownListParentChild from '../DropDownListParentChild/DropDownListParentChild';

const DropDownListMediosPago = (props) => {

  const tipocuentaspago = useSelector((state) => state.common.tipoCuentasPago);

  const [idconceptopadre, setConceptoPadre] = useState("");
  const [idconceptohijo, setConceptoHijo] = useState("");

  const items = tipocuentaspago.map(item => {
    return {
               id: item.IdTipoFormaPago,
               label: item.TipoFormaPagoDescripcion,
               children: item.Cuentas.map(subitem => {
                    return {
                      id: subitem.IdCuenta,
                      label: subitem.CuentaDescripcion
                    };  
               })
             };
  });

  return (
    <>
        <DropDownListParentChild 
          id="MedioPago"
          labelparent="Tipo Medio Pago"
          valueparent={idconceptopadre}
          labelchild="Medio de Pago"
          items={items}
          valuechild={idconceptohijo}
          onChange={props.onChange}
          onChangeParent={props.onChangeParent}
          error={props.error}
        >
        </DropDownListParentChild>
    </>
  )
}

export default DropDownListMediosPago;