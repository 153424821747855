import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar';
import { useNavigate } from "react-router-dom";
import "./Income.css";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import PriceCheckOutlinedIcon  from '@mui/icons-material/PriceCheckOutlined';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import TextInput from '../../components/TextInput/TextInput';
import NumberInput from '../../components/NumberInput/NumberInput';
import DropDownListConceptos from '../../components/DropDownListConceptos/DropDownListConceptos';
import DropDownListMediosPago from '../../components/DropDownListMediosPago/DropDownListMediosPago';
import DropDownListCantidadCuotas from '../../components/DropDownListCantidadCuotas/DropDownListCantidadCuotas';
import Loader from '../../components/Loader/Loader';
import Message from '../../components/Message/Message';
import { useSelector } from 'react-redux';
import { useForm } from "../../hooks/useForm";

import CommonDataService from  '../../services/commonService';

const initialForm = {
    fecha: new Date(),
    tipo: 'I',
    concepto: '',
    importe: 0.00,
    mediopago: '',
    cantidadcuotas: 1,
    cuenta:'',
    descripcion: ''
};

const submit = (token, idgroup, form) =>{
    return CommonDataService.putNewImputacion(token, idgroup, form);
};

const validationsForm = (form) => {
    let errors = {};
    let regexDescripcion = /^.{1,255}$/;
 
    if (!form.descripcion.trim()) {
      errors.descripcion = "El campo 'Descripción' es requerido";
    } else if (!regexDescripcion.test(form.descripcion.trim())) {
      errors.descripcion =
        "El campo 'Descripción' no debe exceder los 255 caracteres";
    }

    if (form.importe <= 0){
        errors.importe =
        "El importe debe ser mayor a cero";
    }

    if (!form.concepto){
        errors.concepto =
        "Debe seleccionar un concepto";
    }

    if (!form.cuenta){
        errors.cuenta =
        "Debe seleccionar un medio de pago";
    }

    return errors;
};

const Income = () => {

    const {
        form,
        errors,
        loading,
        response,
        responseError,
        handleChange,
        handleBlur,
        handleSubmit
    } = useForm(initialForm, validationsForm, submit);

    const [cuotadisabled, setCuotaDisabled] = useState(true);

    const cuentaspago = useSelector((state) => state.common.cuentasPago);

    const handleFechaChange = (newValue) => {
        const e = {
            target:{
                name:'fecha',
                value:newValue
            }
        };
        handleChange(e);
    }

    const handleConceptoChange = (e) => {
        const e_aux = {
            target:{
                name:'concepto',
                value:e.target.value
            }
        };
        handleChange(e_aux);
    }    

    const handleTipoMedioPagoChange = (e) => {
        const e_aux = {
            target:{
                name:'mediopago',
                value:e.target.value
            }
        };
        handleChange(e_aux);
    }

    const handleMedioPagoChange = (e) => {
        const e_aux = {
            target:{
                name:'cuenta',
                value:e.target.value
            }
        };
        handleChange(e_aux);
        const cuenta = cuentaspago.find(element => element.IdCuenta === e.target.value);
        form.cantidadcuotas = 1;
        setCuotaDisabled(!cuenta.EsFinanciado);
    } 

    const handleCantidadCuotasChange = (e) => {
        const e_aux = {
            target:{
                name:'cantidadcuotas',
                value:e.target.value
            }
        };
        handleChange(e_aux);
    }   

    let navigate = useNavigate();

    const buttonSaveHandler = (e) => {
        handleSubmit(e)
            .then(res =>{                
                if(res){
                    navigate("/private");
                }
            } );       

    } 

    const buttonExitHandler = (e) => {
        if(e) e.preventDefault();
        navigate("/private");
    } 


    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Navbar brand="Mis Gastos" />
            <Container component="main" maxWidth="xs">            
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                        <PriceCheckOutlinedIcon sx={{bgcolor: 'success.main' }}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Nuevo ingreso
                    </Typography>                    
                    <Box component="form" noValidate sx={{ p:2, mt: 1 }}>
                        <FormControl fullWidth sx={{ mb:1 }} >
                            <DesktopDatePicker
                                label="Fecha"
                                inputFormat="dd/MM/yyyy"
                                value={form.fecha}
                                onChange={handleFechaChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <DropDownListConceptos
                            tipo="I"
                            id="concepto-select"
                            value={form.concepto}
                            label="Concepto"
                            onChange={handleConceptoChange}
                            error={errors.concepto}
                        />
                        <NumberInput
                            name='importe'
                            label='Importe'
                            onChange={handleChange}
                            value={form.importe}
                            error={errors.importe}
                            required
                        />
                        <DropDownListMediosPago 
                            id='mediopago-select' 
                            label='Medio de pago'
                            value={form.cuenta} 
                            onChangeParent={handleTipoMedioPagoChange}
                            onChange={handleMedioPagoChange}
                            error={errors.mediopago}
                        />
                        <DropDownListCantidadCuotas 
                            id="cantidadcuotas-select"
                            label="Cantidad de cuotas"
                            value={form.cantidadcuotas}
                            onChange={handleCantidadCuotasChange} 
                            disabled={cuotadisabled}                            
                        />
                        <TextInput
                            id="descripcion"
                            label="Descripción del gasto"
                            name="descripcion"
                            value={form.descripcion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.descripcion}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
                            onClick={ buttonSaveHandler }
                        >
                            Grabar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
                            onClick={ buttonExitHandler }
                        >
                            Cancelar
                        </Button>                                            
                    </Box>
                    <Box         
                        display="flex" 
                        alignItems="center"
                        justifyContent="center"
                    >
                        {loading && <Loader />}
                    </Box>
                    {responseError && (
                        <Message msg="Ha ocurrido un error al enviar los datos. Intente nuevamente." bgColor="#ff6961" />
                    )}
                    {response && (
                        <Message msg="Los datos han sido enviados" bgColor="#198754" />
                    )}
                </Box>
            </Container>
  
        </LocalizationProvider> 
        </>
    )
}

export default Income;