import { login, setAuthenticated, startLogin, startLoginError, refreshtoken, logoutAction } from './authSlice';
import { setGroups, setGroup, setMonedas, setMoneda, setPagadores, setConceptosGastos, setConceptosIngresos, setTipoCuentasPago, setCuentasPago, setCuentasMovimiento } from './commonSlice';
import AuthDataService from '../../services/authService';
import CommonDataService from '../../services/commonService';
import jwt from 'jwt-decode';

export const loginThunk = (email, password) => {
    return async (dispatch, getState) => {
        dispatch( startLogin() );
        try{
            const res = await AuthDataService.login(email, password);
            const data_obj = jwt(res.data.token);
            const data = JSON.parse(data_obj.data);
            data.token = res.data.token;
            dispatch( login( data ) );  
            return res.data.token;              
        }catch(error){
            dispatch( startLoginError() );
            return false;
        }       

    }
}

export const setAuthenticatedThunk = () =>{
    return dispatch => {
        dispatch( setAuthenticated() );
    }
}

export const refreshTokenThunk = () => {
    return async (dispatch, getState) => {
        try{
            const res = await AuthDataService.refreshToken()
            const data_obj = jwt(res.data.token);
            const data = JSON.parse(data_obj.data);
            data.token = res.data.token;
            dispatch( refreshtoken( data ) );
            dispatch( setAuthenticated() );
            return true;              
        }catch(error){
            dispatch( logoutAction() );
            return false;
        }       

    }
}

export const commondataThunk = (token) => {
    return async (dispatch, getState) => {
        try{
            const groups = await CommonDataService.getAllGroups(token);
            dispatch( setGroups( groups.data ) );
            dispatch( setGroup( groups.data[0].IdGroup ) );
            const monedas = await CommonDataService.getAllMonedas(token);
            dispatch( setMonedas( monedas.data ) );
            dispatch( setMoneda( monedas.data[0].IdMoneda ) );
            //Obtengo Pagadores
            const pagadores = await CommonDataService.getAllPagadores(token, groups.data[0].IdGroup);
            dispatch( setPagadores( pagadores.data ) );
            //Obtengo Conceptos
            const conceptos = await CommonDataService.getAllConceptos(token, groups.data[0].IdGroup);
            const conceptosgastospadre = conceptos.data.filter((concepto) => (concepto.IdConceptoPadre === null && concepto.IngresoGasto==='G' ));
            const conceptosgastos = conceptosgastospadre.map( (concepto) => {
                concepto['conceptoshijos'] = conceptos.data.filter((conceptohijo) => (conceptohijo.IdConceptoPadre === concepto.IdConcepto && concepto.IngresoGasto==='G'));
                return concepto;
            });
            const conceptosingresospadre = conceptos.data.filter((concepto) => (concepto.IdConceptoPadre === null && concepto.IngresoGasto==='I' ));
            const conceptosingresos = conceptosingresospadre.map( (concepto) => {
                concepto['conceptoshijos'] = conceptos.data.filter((conceptohijo) => (conceptohijo.IdConceptoPadre === concepto.IdConcepto && concepto.IngresoGasto==='I'));
                return concepto;
            });
            dispatch( setConceptosGastos( conceptosgastos ) );
            dispatch( setConceptosIngresos( conceptosingresos ) );
            //Obtengo cuentas de pago
            const cuentasPago = await CommonDataService.getAllCuentasPago(token, groups.data[0].IdGroup, monedas.data[0].IdMoneda);
            const tipocuentaspago = [];
            const cuentaspago = [];
            let idtipoformapagoanterior = -1;
            let i=-1;
            cuentasPago.data.forEach(item => {
                cuentaspago.push({
                    IdCuenta:item.IdCuenta,
                    CuentaDescripcion:item.CuentaDescripcion,
                    EsFinanciado: ((item.EsFinanciado * 1) === 1? true : false),
                    IdTipoFormaPago:item.IdTipoFormaPago
                });
                if ( item.IdTipoFormaPago !== idtipoformapagoanterior ){
                    tipocuentaspago.push({
                        IdTipoFormaPago:item.IdTipoFormaPago,
                        TipoFormaPagoDescripcion:item.TipoFormaPagoDescripcion,
                        Cuentas:[{
                            IdCuenta:item.IdCuenta,
                            CuentaDescripcion:item.CuentaDescripcion
                        }]
                    });
                    i++;
                    idtipoformapagoanterior=item.IdTipoFormaPago;
                }else{
                    tipocuentaspago[i].Cuentas.push({
                        IdCuenta:item.IdCuenta,
                        CuentaDescripcion:item.CuentaDescripcion
                    });
                };
            });            
            dispatch( setTipoCuentasPago( tipocuentaspago ) );
            dispatch( setCuentasPago( cuentaspago ) );
            //Obtengo cuentas de movimiento
            const cuentasMovimiento = await CommonDataService.getAllCuentasMovimiento(token, groups.data[0].IdGroup, monedas.data[0].IdMoneda);
            dispatch( setCuentasMovimiento( cuentasMovimiento.data ) );
            return true;
        }catch(error){
            dispatch( startLoginError() );
            return false;
        }       

    }
}



