import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate } from "react-router-dom";
import AccountBalanceWalletIcon  from '@mui/icons-material/AccountBalanceWallet';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CollapsibleTableAccounts from '../../components/CollapsibleTableAccounts/CollapsibleTableAccounts';

const Accounts = () => {

    let navigate = useNavigate();

    const buttonExitHandler = (e) => {
        if(e) e.preventDefault();
        navigate("/private");
    } 

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Navbar brand="Mis Gastos" />
            <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                <AccountBalanceWalletIcon sx={{bgcolor: 'success.main' }}/>
            </Avatar>
            <Typography component="h1" variant="h5">
                Cuentas
            </Typography>
            <CollapsibleTableAccounts />
            <Button
                type="submit"
                variant="contained"
                sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
                onClick={ buttonExitHandler }
            >
                Volver
            </Button>  
        </LocalizationProvider> 
    )
}

export default Accounts;