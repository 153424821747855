import React from 'react';
import './RowAccountDue.css';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, TableRow, TableCell, TableBody, TableHead, Box, Table } from '@material-ui/core';
import { Fragment } from 'react';
import Currency from '../../components/Currency/Currency';
import Loader from '../../components/Loader/Loader';
import CommonDataService from  '../../services/commonService';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const RowAccountDue = (props) => {

    const { row, columns} = props;
    const [isDataFetch, setIsDataFetch] = useState(false);
    const [movimientos, setMovimientos] = useState([]);
    const [open, setOpen] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [datosTarjeta, setDatosTarjeta] = useState({});

    const token = useSelector((state) => state.auth.token);
    const idgroup = useSelector((state) => state.common.idGroup);
    const idmoneda = useSelector((state) => state.common.idMoneda);

    const expandIconClickHandler = (idCuenta) => {
        setOpen(!open);
        if(!isDataFetch){
            setIsDetailLoading(true);
            CommonDataService.getAllProximasCuotasTarjetaDetalle(token, idgroup, idmoneda, idCuenta)
                .then(res =>{
                    let months = columns;
                    let movimientos=[];
                    let idimputacionant=null;
                    let i=-1;
                    res.data.detail.forEach(item =>{
                        let fechaPeriodo=getDate(item.FechaPeriodo);
                        if (idimputacionant !== item.IdImputacion){
                            movimientos.push({idimputacion: item.IdImputacion, fechaimputacion: item.FechaImputacion, descripcion:item.Descripcion, cols: Array(months.length).fill(0.0)});
                            i++;
                        };
                        let index = months.findIndex( (month) => ( fechaPeriodo.getTime() === month.getTime() ) )
                        movimientos[i].cols[index]=item.ImporteCuota;
                        idimputacionant=item.IdImputacion; 
                    });
                    let fechaVencimiento = getShortDate(getDate(res.data.head.FechaVencimiento));
                    let fechaInicioResumen = getShortDate(getDate(res.data.head.FechaInicioPeriodo));
                    let fechaFinResumen = getShortDate(getDate(res.data.head.FechaFinPeriodo));

                    setDatosTarjeta({FechaVencimiento:fechaVencimiento, FechaInicioPeriodo:fechaInicioResumen, FechaFinPeriodo: fechaFinResumen });
                    setMovimientos(movimientos);
                    setIsDataFetch(true);
                    setIsDetailLoading(false);
                });           
        }
    };

    const getMonth = (adate) => {
        const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'];
        return months[adate.getMonth()] + "/" + adate.getFullYear();
    }

    const getShortDate = (adate) => {
        const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Set', 'Oct', 'Nov', 'Dic'];
        return  adate.getDate() + "/" + months[adate.getMonth()];
    }

    const getDate = ( dateStr ) => {
        const [year, month, day] = [...dateStr.split("-")];
        const monthIndex = month - 1; 
        return new Date(year, monthIndex, day);
    }

    return (   
        <Fragment key={row.idcuenta}>
        <TableRow key={row.idcuenta+'_row'} sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell>
                <Button onClick={() => expandIconClickHandler(row.idcuenta)}>
                    {open ? "-" : "+"}
                </Button>
            </TableCell>
            <TableCell component="th" scope="row">{row.cuenta}</TableCell>
            {row.cols.map((col, index) => (
            <TableCell key={index} align="right"><Currency value={col}/></TableCell>
            ))}
        </TableRow>
        <TableRow key={row.idcuenta+'_rowdetail'}>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Datos del último resúmen
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                inicio: {datosTarjeta.FechaInicioPeriodo}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                fin: {datosTarjeta.FechaFinPeriodo}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                vencimiento: {datosTarjeta.FechaVencimiento} 
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{ margin: 1 }}>
                <h2>
                    Detalle de movimientos
                </h2>
                {isDetailLoading && <Loader />}
                <Table sx={{ minWidth: 120 }} size="small" aria-label="purchases">
                    <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Gasto</TableCell>
                        {columns.map((col, index) => (
                            <TableCell key={index} align="right">{getMonth(col)}</TableCell>
                        ))}  
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {movimientos.map((movimiento, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{movimiento.fechaimputacion}</TableCell>
                            <TableCell>{movimiento.descripcion}</TableCell>
                            {movimiento.cols.map((col, index) => (
                            <TableCell key={index} align="right"><Currency value={col}/></TableCell>
                            ))}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        </Fragment>
    )
}

export default RowAccountDue;