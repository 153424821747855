import React from "react";
import FormControl from '@mui/material/FormControl';
import MessageError from '../MessageError/MessageError';
import DropDownList from '../DropDownList/DropDownList';

const DropDownListTipoMovimiento = (props) => {

  const items = [
                    {id:'T', label:'Transferencia entre cuentas bancarias'},
                    {id:'M', label:'Transferencia entre cuentas efectivo'},
                    {id:'E', label:'Extracción en efectivo desde cuenta bancaria'},
                    {id:'D', label:'Depósito de efectivo a cuenta bancaria'}
                ];

  return (
    <FormControl fullWidth sx={{ mb:1 }} >
        <DropDownList
            id='tipomovimiento'
            label='Tipo de movimiento'
            value = {props.value}
            onChange = {props.onChange}
            disabled = {props.disabled}
            items={items}
        >
        </DropDownList>
        <MessageError errors={props.error} />
    </FormControl>  
  );
};

export default DropDownListTipoMovimiento;