import React from 'react';
import './SwitchCuentasPropias.css';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const SwitchCuentasPropias = (props) => {

  return (
    <FormGroup>
        <FormControlLabel control={<Switch checked={props.value} onChange={props.onChange} />} label={props.value ? "a cuentas propias" : "a cuentas de otros miembros del grupo"} />
    </FormGroup>
  )
}

export default SwitchCuentasPropias;
