import React from "react";
import FormControl from '@mui/material/FormControl';
import MessageError from '../MessageError/MessageError';
import TextField from '@mui/material/TextField';

const TextInput = (props) => {
  return (
    <FormControl fullWidth sx={{ mb:1 }} >
        <TextField
            id={props.id}
            label={props.label}
            name={props.name}
            multiline={props.multiline}
            maxRows={props.maxRows}            
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            type={props.type}
            fullWidth={props.fullWidth}
            autoComplete={props.autoComplete}
            required={props.required}
            autoFocus={props.autoFocus}
        />
        <MessageError errors={props.error} />
    </FormControl>  
  );
};

export default TextInput;