import React from 'react';
import './CollapsibleTableAccounts.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Row from '../RowAccount/RowAccount';
import Loader from '../../components/Loader/Loader';
import Currency from '../../components/Currency/Currency';
import CommonDataService from  '../../services/commonService';
import SwitchIncluyeTarjetas from '../SwitchIncluyeTarjetas/SwitchIncluyeTarjetas';

const CollapsibleTableAccounts = () => {

    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalSaldoCuenta, setTotalSaldoCuenta] = useState(0.00);
    const [incluyeTarjetas, setIncluyeTarjetas] = useState(false);

    const token = useSelector((state) => state.auth.token);
    const idgroup = useSelector((state) => state.common.idGroup);
    const idmoneda = useSelector((state) => state.common.idMoneda);

    useEffect(() => {
        setIsLoading(true);
        CommonDataService.getAllCuentasSaldos(token, idgroup, idmoneda)
            .then(res =>{
                setRows(res.data);
                let totSaldoCuenta = 0.00;
                res.data.forEach(item =>{
                    totSaldoCuenta += ( false || item.EsFinanciado===0) ? item.Saldo : 0.00;
                });
                setTotalSaldoCuenta(totSaldoCuenta);
                setIsLoading(false);
            });           
    }, [token, idgroup, idmoneda]);
    
    const handleChangeIncluyeTarjetas = (event) => {
        setIncluyeTarjetas(event.target.checked);
        let totSaldoCuenta = 0.00;
        rows.forEach(item =>{
            totSaldoCuenta += (event.target.checked || item.EsFinanciado===0) ? item.Saldo : 0.00;
        });
        setTotalSaldoCuenta(totSaldoCuenta);

    };   

    return (
        <>
            <Box sx={{ display: 'flex', m:2 }}>
                <SwitchIncluyeTarjetas onChange={handleChangeIncluyeTarjetas} value={incluyeTarjetas} ></SwitchIncluyeTarjetas>
            </Box>
            {isLoading && <Loader />}
            <TableContainer>        
                <Table sx={{ minWidth: 90 }} size="small" aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell align="right" >Total:</TableCell>
                            <TableCell align="right" ><Currency sx={{fontWeight: 'bold'}} value={totalSaldoCuenta}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell />
                            <TableCell>Cuenta</TableCell>
                            <TableCell align="right">Saldo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.filter((row)=> ( incluyeTarjetas || row.EsFinanciado===0)).map((row) => (
                            <Row key={row.IdCuenta} row={row} />
                        ))}  
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CollapsibleTableAccounts;
