import React, { useState } from 'react'
import Navbar from '../../components/Navbar/Navbar';
import { useNavigate } from "react-router-dom";
import "./Transfer.css";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import TextInput from '../../components/TextInput/TextInput';
import NumberInput from '../../components/NumberInput/NumberInput';
import DropDownListTipoMovimiento from '../../components/DropDownListTipoMovimiento/DropDownListTipoMovimiento'; 
import DropDownListCuentas from '../../components/DropDownListCuentas/DropDownListCuentas';
import Loader from '../../components/Loader/Loader';
import Message from '../../components/Message/Message';
import { useForm } from "../../hooks/useForm";
import CommonDataService from  '../../services/commonService';
import SwitchCuentasPropias from '../../components/SwitchCuentasPropias/SwitchCuentasPropias';

const initialForm = {
    fecha: new Date(),
    tipomovimiento: '',
    cuentaorigen: '',
    cuentadestino: '',
    importe: 0.00,
    descripcion: ''
};

const submit = (token, idgroup, form) =>{
    return CommonDataService.putNewMovimiento(token, idgroup, form);
};

const validationsForm = (form) => {
    let errors = {};
    let regexDescripcion = /^.{1,150}$/;
 
    if (!form.descripcion.trim()) {
      errors.descripcion = "El campo 'Descripción' es requerido";
    } else if (!regexDescripcion.test(form.descripcion.trim())) {
      errors.descripcion =
        "El campo 'Descripción' no debe exceder los 150 caracteres";
    }

    if (form.importe <= 0){
        errors.importe =
        "El importe debe ser mayor a cero";
    }

    if (!form.tipomovimiento){
        errors.concepto =
        "Debe seleccionar un tipo movimiento";
    }

    if (!form.cuentaorigen){
        errors.cuentaorigen =
        "Debe seleccionar una cuenta de origen";
    }

    if (!form.cuentadestino){
        errors.cuentadestino =
        "Debe seleccionar una cuenta de destino";
    }

    return errors;
};

const Transfer = () => {

    const {
        form,
        errors,
        loading,
        response,
        responseError,
        handleChange,
        handleBlur,
        handleSubmit
    } = useForm(initialForm, validationsForm, submit);

    const [cuentasdisabled, setCuentasDisabled] = useState(true);
    const [tipomovimiento, setTipoMovimiento] = useState("");
    const [cuentaspropias, setCuentasPropias] = useState(false);

    const handleFechaChange = (newValue) => {
        const e = {
            target:{
                name:'fecha',
                value:newValue
            }
        };
        handleChange(e);
    }

    const handleTipoMovimientoChange = (e) => {
        const e_aux = {
            target:{
                name:'tipomovimiento',
                value:e.target.value
            }
        };
        setTipoMovimiento(e.target.value)
        setCuentasDisabled(false);
        handleChange(e_aux);
    }    

    const handleCuentaOrigenChange = (e) => {
        const e_aux = {
            target:{
                name:'cuentaorigen',
                value:e.target.value
            }
        };
        handleChange(e_aux);
    }

    const handleCuentaDestinoChange = (e) => {
        const e_aux = {
            target:{
                name:'cuentadestino',
                value:e.target.value
            }
        };
        handleChange(e_aux);
    }

    const handleChangeCuentasPropias = (event) => {
        setCuentasPropias(event.target.checked);
    };      

    let navigate = useNavigate();

    const buttonSaveHandler = (e) => {
        handleSubmit(e)
            .then(res =>{                
                if(res){
                    navigate("/private");
                }
            } );       

    } 

    const buttonExitHandler = (e) => {
        if(e) e.preventDefault();
        navigate("/private");
    } 


    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Navbar brand="Mis Gastos" />
            <Container component="main" maxWidth="xs">            
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'success.main' }}>
                        <CurrencyExchangeOutlinedIcon sx={{bgcolor: 'success.main' }}/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Nueva Transferencia entre cuentas
                    </Typography>                    
                    <Box component="form" noValidate sx={{ p:2, mt: 1 }}>
                        <FormControl fullWidth sx={{ mb:1 }} >
                            <DesktopDatePicker
                                label="Fecha"
                                inputFormat="dd/MM/yyyy"
                                value={form.fecha}
                                onChange={handleFechaChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </FormControl>
                        <DropDownListTipoMovimiento
                            value={form.tipomovimiento}
                            onChange={handleTipoMovimientoChange}
                            error={errors.tipomovimiento}
                        />                        
                        <SwitchCuentasPropias
                            onChange={handleChangeCuentasPropias} 
                            value={cuentaspropias} 
                        />
                        <DropDownListCuentas
                            id="cuenta-origen"
                            origendestino="O"
                            tipomovimiento={tipomovimiento}
                            grupo='N'
                            value={form.cuentaorigen}
                            label="Cuenta Origen"
                            onChange={handleCuentaOrigenChange}
                            error={errors.cuentaorigen}
                            disabled={cuentasdisabled} 
                        />
                        <DropDownListCuentas
                            id="cuenta-destino"
                            origendestino="D"
                            tipomovimiento={tipomovimiento}
                            grupo={ cuentaspropias ? 'N' : 'S'}
                            value={form.cuentadestino}
                            label="Cuenta Destino"
                            onChange={handleCuentaDestinoChange}
                            error={errors.cuentadestino}
                            disabled={cuentasdisabled}
                        />                        
                        <NumberInput
                            name='importe'
                            label='Importe'
                            onChange={handleChange}
                            value={form.importe}
                            error={errors.importe}
                            required
                        />
                        <TextInput
                            id="descripcion"
                            label="Descripción del gasto"
                            name="descripcion"
                            value={form.descripcion}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={errors.descripcion}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
                            onClick={ buttonSaveHandler }
                        >
                            Grabar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 1, mb: 2, mx: 2, width:'40%' }}
                            onClick={ buttonExitHandler }
                        >
                            Cancelar
                        </Button>                                            
                    </Box>
                    <Box         
                        display="flex" 
                        alignItems="center"
                        justifyContent="center"
                    >
                        {loading && <Loader />}
                    </Box>
                    {responseError && (
                        <Message msg="Ha ocurrido un error al enviar los datos. Intente nuevamente." bgColor="#ff6961" />
                    )}
                    {response && (
                        <Message msg="Los datos han sido enviados" bgColor="#198754" />
                    )}
                </Box>
            </Container>
  
        </LocalizationProvider> 
        </>
    )
}

export default Transfer;