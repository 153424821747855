import React from 'react';
import "./ForgotPassword.css";
import {useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TextInput from '../../components/TextInput/TextInput';
import Loader from '../../components/Loader/Loader';
import Copyright from '../../components/Copyright/Copyright';
import AuthDataService from '../../services/authService';
import MessageError from '../../components/MessageError/MessageError';

const ForgotPassword = () => {

    const [email, setEMail] = useState('');
    const [emailError, setEMailError] = useState('');
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const buttonHandler = (e) => {
        e.preventDefault();
        if(handleValidation()){
            setIsLoading(true);
            AuthDataService.sendResetPassword(email)
                .then((res) => {
                    setIsLoading(false);
                    if (!res){
                        setResetPasswordError("Hemos enviado a su correo la solicitud de cambio de contraseña. Por favor chequee su casilla de correo");
                    }else{
                        setResetPasswordError("Hemos enviado a su correo la solicitud de cambio de contraseña. Por favor chequee su casilla de correo");
                    }                  
                })
                .catch((error) => {
                    if(error.response.status === 401){
                      setResetPasswordError("Hemos enviado a su correo la solicitud de cambio de contraseña. Por favor chequee su casilla de correo");
                    }else{
                      setResetPasswordError("Ha ocurrido un error. Por favor reintente");
                    };
                    setIsLoading(false);
                  });              
        }
    } 

    const handleValidation = () => {
        if (!email.match(/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/)){
            setEMailError("Debe ingresar una cuenta de correo electrónico válida");
            return false;
        } else {
            setEMailError("");
        }
        return true;
    }

    return (
        <Container component="main" maxWidth="xs" >
        <Box
          sx={{
            marginTop: 8,
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: "auto", bgcolor:'secondary.main' }}>
            <LockOutlinedIcon sx={{ bgcolor:'secondary.main'  }} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Mis Gastos - Reseteo de contraseña
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextInput
              id="email"
              label="Correo electrónico"
              name="email"
              value={email}
              onChange={e => setEMail(e.target.value)}
              error={emailError}
              autoComplete="email"
              required
              autoFocus
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              onClick={buttonHandler}
            >
              Enviar
            </Button>
            <Box         
              display="flex" 
              alignItems="center"
              justifyContent="center"
            >
              {isLoading && <Loader/>}
            </Box>
            <MessageError errors={resetPasswordError} />        
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    );
};

export default ForgotPassword;