import http from "./http-common";

class commonService {

    setAutorization(token){
        return {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    }
 
    getAllGroups = (token) => http.get('/groups', this.setAutorization(token));

    getAllPagadores = (token, idgroup) => http.get(`/pagadores/${idgroup}`, this.setAutorization(token));

    getAllMonedas = (token) => http.get('/monedas', this.setAutorization(token));

    getAllConceptos = (token, idgroup) => http.get(`/conceptos/${idgroup}`, this.setAutorization(token));

    getAllCuentasPago = (token, idgroup, idmoneda) => http.get(`/cuentaspago/${idgroup}/${idmoneda}`, this.setAutorization(token));

    getAllCuentasMovimiento = (token, idgroup, idmoneda) => http.get(`/cuentasmovimiento/${idgroup}/${idmoneda}`, this.setAutorization(token));

    getAllCuentasSaldos = (token, idgroup, idmoneda) => http.get(`/cuentassaldos/${idgroup}/${idmoneda}`, this.setAutorization(token));

    getAllConceptosSaldos = (token, idgroup, idmoneda, esfinanciero, pagadores) => http.get(`/conceptossaldos/${idgroup}/${idmoneda}/${esfinanciero?1:0}?pag=${pagadores}`, this.setAutorization(token));

    getAllCuentaMovimientos = (token, idgroup, idcuenta) => http.get(`/cuentamovimientos/${idgroup}/${idcuenta}`, this.setAutorization(token));

    getAllConceptoMovimientos = (token, idgroup, idconcepto, idperiodo, pagadores) => http.get(`/conceptomovimientos/${idgroup}/${idconcepto}/${idperiodo}?pag=${pagadores}`, this.setAutorization(token));

    getAllProximosVencimientos = (token, idgroup, idmoneda) => http.get(`/proximosvencimientos/${idgroup}/${idmoneda}`, this.setAutorization(token));

    getAllProximasCuotasTarjeta = (token, idgroup, idmoneda) => http.get(`/cuentacuotas/${idgroup}/${idmoneda}`, this.setAutorization(token));

    getAllProximasCuotasTarjetaDetalle = (token, idgroup, idmoneda, idcuenta) => http.get(`/cuentacuotasdetalle/${idgroup}/${idmoneda}/${idcuenta}`, this.setAutorization(token));

    putNewImputacion = (token, idgroup, imputacion) => http.post(`/${idgroup}/imputaciones`, imputacion, this.setAutorization(token));

    putNewMovimiento = (token, idgroup, movimiento) => http.post(`/${idgroup}/movimientos`, movimiento, this.setAutorization(token));

};

export default new commonService();