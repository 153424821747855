import React, { useEffect } from 'react'
import { refreshTokenThunk } from '../../store/slices';
import { useSelector, useDispatch } from 'react-redux';

export const AuthControl = () => {

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const token = useSelector((state) => state.auth.token);

    const dispatch = useDispatch();

    useEffect(() => {     
        if ( isAuthenticated && !token ){
            dispatch( refreshTokenThunk() );        
        }
    },[dispatch, isAuthenticated, token]);

  return (
    <></>
  )
}
