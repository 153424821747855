import React from 'react';
import './CollapsibleTableConcepts.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@material-ui/core';
import Row from '../RowConcept/RowConcept';
import Loader from '../../components/Loader/Loader';
import Currency from '../../components/Currency/Currency';
import DropDownList from '../../components/DropDownList/DropDownList';
import CommonDataService from  '../../services/commonService';
import SwitchEsFinanciero from '../SwitchEsFinanciero/SwitchEsFinanciero';

const CollapsibleTableConcepts = () => {

    const token = useSelector((state) => state.auth.token);
    const idgroup = useSelector((state) => state.common.idGroup);
    const idmoneda = useSelector((state) => state.common.idMoneda);
    const pagadores = useSelector((state) => state.common.pagadores);
    const idpagador = useSelector((state) => state.auth.idpagador);    

    const [rows, setRows] = useState([]);
    const [idperiodo, setIdPeriodo] = useState('');
    const [idPagadores, setIdPagadores] = useState([idpagador]);
    const [periodos, setPeriodos] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPresupuesto, setTotalPresupuesto] = useState(0.00);
    const [totalGasto, setTotalGasto] = useState(0.00);
    const [itemsPagadores, setItemsPagadores] = useState([]);
    const [esFinanciero, setEsFinanciero] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        setItemsPagadores(pagadores.map( item => {return {id: item.IdPagador, label:item.Pagador}}));
        const pagadores_str = idpagador;
        CommonDataService.getAllConceptosSaldos(token, idgroup, idmoneda, esFinanciero, pagadores_str)
            .then(res =>{
                const now = new Date();
                let idPeriodoActual = null;
                let idperiodoanterior = null;
                const registros = [];
                res.data.forEach(element => {                    
                    if (element.IdPeriodo !== idperiodoanterior){
                        registros.push({
                                        id: element.IdPeriodo,
                                        label: element.PeriodoDescripcion,
                                        gastos: []
                                    });

                        let fechadesde = new Date(element.PeriodoFechaDesde);
                        let fechahasta = new Date(element.PeriodoFechaHasta);
                        if (fechadesde <= now && now <= fechahasta){
                            idPeriodoActual = element.IdPeriodo;
                        }
                    }
                    idperiodoanterior = element.IdPeriodo;
                    registros[registros.length-1].gastos.push({ 
                        idConcepto: element.IdConcepto,
                        idConceptoPadre: element.IdConceptoPadre,
                        conceptoDescripcion: element.ConceptoDescripcion,
                        conceptoPadreDescripcion: element.ConceptoPadreDescripcion,            
                        totalPresupuesto: element.TotalPresupuesto,
                        totalGasto: element.TotalGasto                         
                    });
                });
                setPeriodos(registros);
                setIdPeriodo(idPeriodoActual);
                const periodo = registros.find(element => element.id === idPeriodoActual);
                setRows(periodo.gastos);
                let totPresupuesto = 0.00;
                let totGasto = 0.00;
                periodo.gastos.forEach(item =>{
                    totPresupuesto += item.totalPresupuesto;
                    totGasto += item.totalGasto;
                });
                setTotalPresupuesto(totPresupuesto);
                setTotalGasto(totGasto);
                setIsLoading(false);   
            });  
    }, [token, idgroup, idmoneda, idpagador, esFinanciero, pagadores]);
    
    const handlePeriodoChange = (e) => {
        setIdPeriodo(e.target.value);
        const periodo = periodos.find(element => element.id === e.target.value);
        setRows(periodo.gastos);
        let totPresupuesto = 0.00;
        let totGasto = 0.00;
        periodo.gastos.forEach(item =>{
            totPresupuesto += item.totalPresupuesto;
            totGasto += item.totalGasto;
        });
        setTotalPresupuesto(totPresupuesto);
        setTotalGasto(totGasto);
    }   

    const handlePagadorChange = (e) => {
        const {
            target: { value },
         } = e;
        setIdPagadores(typeof value === 'string' ? value.split(',') : value);
        setIsLoading(true);
        setRows([]);
        CommonDataService.getAllConceptosSaldos(token, idgroup, idmoneda, esFinanciero, value.join())
            .then(res =>{
                const now = new Date();
                let idPeriodoActual = null;
                let idperiodoanterior = null;
                const registros = [];
                res.data.forEach(element => {                    
                    if (element.IdPeriodo !== idperiodoanterior){
                        registros.push({
                                        id: element.IdPeriodo,
                                        label: element.PeriodoDescripcion,
                                        gastos: []
                                    });

                        let fechadesde = new Date(element.PeriodoFechaDesde);
                        let fechahasta = new Date(element.PeriodoFechaHasta);
                        if (fechadesde <= now && now <= fechahasta){
                            idPeriodoActual = element.IdPeriodo;
                        }
                    }
                    idperiodoanterior = element.IdPeriodo;
                    registros[registros.length-1].gastos.push({ 
                        idConcepto: element.IdConcepto,
                        idConceptoPadre: element.IdConceptoPadre,
                        conceptoDescripcion: element.ConceptoDescripcion,
                        conceptoPadreDescripcion: element.ConceptoPadreDescripcion,            
                        totalPresupuesto: element.TotalPresupuesto,
                        totalGasto: element.TotalGasto                         
                    });
                });
                setPeriodos(registros);
                setIdPeriodo(idPeriodoActual);
                const periodo = registros.find(element => element.id === idPeriodoActual);
                setRows(periodo.gastos);
                let totPresupuesto = 0.00;
                let totGasto = 0.00;
                periodo.gastos.forEach(item =>{
                    totPresupuesto += item.totalPresupuesto;
                    totGasto += item.totalGasto;
                });
                setTotalPresupuesto(totPresupuesto);
                setTotalGasto(totGasto);
                setIsLoading(false);   
            });  
    } 

    const handleChangeEsFinanciero = (event) => {
        setEsFinanciero(event.target.checked);
        setIsLoading(true);
        setRows([]);
        CommonDataService.getAllConceptosSaldos(token, idgroup, idmoneda, event.target.checked, idPagadores.join())
            .then(res =>{
                const now = new Date();
                let idPeriodoActual = null;
                let idperiodoanterior = null;
                const registros = [];
                res.data.forEach(element => {                    
                    if (element.IdPeriodo !== idperiodoanterior){
                        registros.push({
                                        id: element.IdPeriodo,
                                        label: element.PeriodoDescripcion,
                                        gastos: []
                                    });

                        let fechadesde = new Date(element.PeriodoFechaDesde);
                        let fechahasta = new Date(element.PeriodoFechaHasta);
                        if (fechadesde <= now && now <= fechahasta){
                            idPeriodoActual = element.IdPeriodo;
                        }
                    }
                    idperiodoanterior = element.IdPeriodo;
                    registros[registros.length-1].gastos.push({ 
                        idConcepto: element.IdConcepto,
                        idConceptoPadre: element.IdConceptoPadre,
                        conceptoDescripcion: element.ConceptoDescripcion,
                        conceptoPadreDescripcion: element.ConceptoPadreDescripcion,            
                        totalPresupuesto: element.TotalPresupuesto,
                        totalGasto: element.TotalGasto                         
                    });
                });
                setPeriodos(registros);
                setIdPeriodo(idPeriodoActual);
                const periodo = registros.find(element => element.id === idPeriodoActual);
                setRows(periodo.gastos);
                let totPresupuesto = 0.00;
                let totGasto = 0.00;
                periodo.gastos.forEach(item =>{
                    totPresupuesto += item.totalPresupuesto;
                    totGasto += item.totalGasto;
                });
                setTotalPresupuesto(totPresupuesto);
                setTotalGasto(totGasto);
                setIsLoading(false);   
            });

    };  

    return (
        <>   
            <Box sx={{ display: 'flex', m:2 }}>
                <DropDownList
                    id='periodos'
                    label=''
                    value = {idperiodo}
                    onChange = {handlePeriodoChange}
                    items={periodos}
                ></DropDownList>
                <DropDownList
                    id='pagador'
                    label=''
                    multiple
                    value = {idPagadores}
                    onChange = {handlePagadorChange}
                    items={itemsPagadores}
                ></DropDownList>
                <SwitchEsFinanciero onChange={handleChangeEsFinanciero} value={esFinanciero} ></SwitchEsFinanciero>
            </Box>
            {isLoading && <Loader />}
            <TableContainer>        
            <Table sx={{ minWidth: 90 }} size="small" aria-label="collapsible table">
            <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell align="right" >Totales:</TableCell>
                    <TableCell align="right" sx={{fontWeight: 'bold'}}><Currency value={totalPresupuesto}/></TableCell>
                    <TableCell align="right" sx={{fontWeight: 'bold'}}><Currency value={totalGasto}/></TableCell>
                    <TableCell align="right" sx={{fontWeight: 'bold'}}><Currency value={totalPresupuesto - totalGasto}/></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell />
                    <TableCell align="left" sx={{fontWeight: 'bold'}}>Concepto Padre</TableCell>
                    <TableCell align="left" sx={{fontWeight: 'bold'}}>Concepto</TableCell>
                    <TableCell align="right" sx={{fontWeight: 'bold'}}>Presupuesto</TableCell>
                    <TableCell align="right" sx={{fontWeight: 'bold'}}>Incurrido</TableCell>
                    <TableCell align="right" sx={{fontWeight: 'bold'}}>Disponible</TableCell>                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <Row key={row.idConcepto} row={row} idperiodo={idperiodo} idpagadores={idPagadores} />
                ))} 
            </TableBody>
            </Table>
        </TableContainer>
    </>
    )
}

export default CollapsibleTableConcepts;
